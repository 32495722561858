<template>
  <div v-if="isShow" class="w-full bg-red-dark py-1 text-center text-white">
    {{ message }}
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { isAllowLocalStorage, isAllowCookie } from "~/helpers/permissions";
import isOnline from "~/helpers/isOnline";
const { t } = useI18n({ useScope: "global" });
const isShow = ref(false);

const message = computed(() => {
  if (isOnline().value == false) {
    isShow.value = true;
    return t("noInternet");
  } else if (isAllowLocalStorage() === false || isAllowCookie() === false) {
    isShow.value = true;
    return t("allowCookie");
  }

  isShow.value = false;
  return "";
});
</script>

<script lang="ts">
export default {
  name: "OfflineBanner",
};
</script>
