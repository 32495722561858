<template>
  <div
    id="navbar"
    class="navbar-wrapper sticky mx-auto w-full bg-white"
    :class="[!anyOverlay ? ' z-48' : null, isDesktop ? 'p-1' : null]"
    style="top: 0"
  >
    <OfflineBanner />

    <div class="max-width hh-black flex items-center lg:relative lg:px-2">
      <component :is="hamburgerMenu" v-if="isMobile" />
      <component :is="logoComponent" />
      <!-- Login / Username Mobile -->
      <div class="flex-auto"></div>
      <component :is="navigationComponent" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useSessionStorage } from "@vueuse/core";
import OfflineBanner from "~/components/common/OfflineBanner.vue";
import { defineAsyncComponent, markRaw, onMounted } from "#imports";
import useClientTypeStore from "~/stores/clientType";
import { LOCAL_STORAGE_ANY_OVERLAY } from "~/constants";

const clientTypeStore = useClientTypeStore();
const { isDesktop, isMobile } = storeToRefs(clientTypeStore);
const anyOverlay = useSessionStorage(LOCAL_STORAGE_ANY_OVERLAY, false);

function onScrollNavbar() {
  window.document.onscroll = () => {
    const navBar = document.getElementById("navbar");
    const logoHH = document.getElementById("logo-hh");
    if (navBar && logoHH && window.scrollY > navBar.offsetHeight) {
      logoHH.classList.remove("absolute");
      logoHH.style.top = "";
      navBar.classList.add("shadow");
    } else if (logoHH && navBar) {
      logoHH.classList.add("absolute");
      logoHH.style.top = "-18px";
      navBar.classList.remove("shadow");
    }
  };
}

onMounted(() => {
  if (isDesktop) {
    onScrollNavbar();
  }
});

const navigationComponent = markRaw(
  defineAsyncComponent(() => import("~/section/Navigation.vue"))
);

const logoComponent = markRaw(
  defineAsyncComponent(() => import("~/components/common/Navbar/Logo.vue"))
);

const hamburgerMenu = markRaw(
  defineAsyncComponent(() => import("~/section/HamburgerMenu.vue"))
);
</script>

<script lang="ts">
export default {
  name: "Navbar",
};
</script>
